$theme: 'refititBlue';
@use '../../sass/exts/custom.scss' as custom;

.refitit-select__control[class*='-control'] {
  @include custom.mimicInput;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
  padding-left: 1px;
}

// div.refitit-select__input-container {
//   padding: 0;
//   // margin: 0;
// }
